export const DEFAULT_NUMBER_PRODUCTS_PER_PAGE = 30;
export const SORTING_OPTIONS_NEW = 'new';
export const SORTING_OPTIONS_POPULAR_ASC = 'popularAsc';
export const SORTING_OPTIONS_POPULAR_DESC = 'popularDesc';
export const SORTING_OPTIONS_PRICE_ASC = 'priceAsc';
export const SORTING_OPTIONS_PRICE_DESC = 'priceDesc';
export const SORTING_OPTIONS_RELEVANCE = 'relevance';

export default function getSearchPayload({
  brandsFilter = [],
  categoryFilter = [],
  colorsFilter = [],
  currentPage = 1,
  featuresFilter = [],
  gendersFilter = [],
  modelsFilter = [],
  priceFrom,
  priceTo,
  searchTerm = '',
  shopsFilter = [],
  sizesFilter = [],
  size = DEFAULT_NUMBER_PRODUCTS_PER_PAGE,
  sort = SORTING_OPTIONS_RELEVANCE,
  wpCategory,
  context = 'search',
  uberfilter = null,
}) {
  let sortBy = sort;
  let sortDirection = undefined;
  if (SORTING_OPTIONS_PRICE_ASC === sort) {
    sortBy = 'price';
    sortDirection = 'asc';
  } else if (SORTING_OPTIONS_PRICE_DESC === sort) {
    sortBy = 'price';
    sortDirection = 'desc';
  } else if (SORTING_OPTIONS_POPULAR_ASC === sort) {
    sortBy = 'popular';
    sortDirection = 'asc';
  } else if (SORTING_OPTIONS_POPULAR_DESC === sort) {
    sortBy = 'popular';
    sortDirection = 'desc';
  }

  const price = !isNaN(priceFrom) ? [priceFrom, priceTo] : undefined;

  return {
    payload: {
      filters: {
        brand: brandsFilter.length > 0 ? brandsFilter : undefined,
        campaign: shopsFilter.length > 0 ? shopsFilter : undefined,
        category: categoryFilter.length > 0 ? categoryFilter : undefined,
        color: colorsFilter.length > 0 ? colorsFilter : undefined,
        gender: gendersFilter.length > 0 ? gendersFilter : undefined,
        model: modelsFilter.length > 0 ? modelsFilter : undefined,
        price,
        size: sizesFilter.length > 0 ? sizesFilter : undefined,
        ...featuresFilter.reduce((output, obj) => ({ ...output, [obj.key]: obj.values }), {}),
      },
      uber_ids: uberfilter,
      page: {
        current: currentPage - 1,
        size,
      },
      q: searchTerm || '',
      sort: {
        by: sortBy,
        direction: sortDirection,
      },
    },
    wpCategory,
    context,
  };
}
