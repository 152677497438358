import { useDispatch, useSelector } from 'react-redux';
import { addSearchHistoryItem, setSearchHistory } from '../state/searchHistory/searchHistory';
import { useEffect } from 'react';

export const useSearchHistory = () => {
  const dispatch = useDispatch();
  const searchHistory = useSelector(({ searchHistory }) => searchHistory);

  const addHistoryItem = (searchQuery) => {
    const trimmedSearchQuery = searchQuery?.trim();
    if (trimmedSearchQuery && !searchHistory.includes(trimmedSearchQuery)) {
      dispatch(addSearchHistoryItem(trimmedSearchQuery));
    }
  };
  const removeHistoryItem = (searchQuery) => {
    const updatedHistory = searchHistory.filter((item) => item !== searchQuery);
    dispatch(setSearchHistory(updatedHistory));
  };

  useEffect(() => {
    if (Array.isArray(searchHistory)) localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchHistory?.length]);

  return {
    addHistoryItem,
    searchHistory,
    removeHistoryItem,
  };
};
